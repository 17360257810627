@import "../../bootstrap-3.3.7/less/variables";
@import "../../bootstrap-3.3.7/less/mixins";


html,
body {
  overflow: visible;
}

body {
  position: inherit !important;

  .site__content,
  .site__content.sticky {
    margin-top: 0;
  }
}

// hide href from bootstrap default
a[href]:after {
  content: "";
}

abbr[title]:after {
  content: "";
}

.breadcrumb-section {
  border-top: 2px solid @nav-border-color;
  border-bottom: 2px solid @nav-border-color;
}

// reset cols
.make-grid(xs);
.make-grid(md);
.make-grid(sm);

.footer__left {
  width: 100%;
}

.footer__right {
  display: none;
}


.header-alerts-section-wrapper,
.meta-nav-wrapper,
.header__wrapper,
.footer__wrapper{
  display: none !important;
}

//home
.owl-wrapper {
  width: 100% !important;
}

.owl-carousel .owl-wrapper-outer .owl-wrapper .owl-item {
  width: 25% !important;
  display: none !important;
}

.owl-carousel .owl-wrapper-outer .owl-wrapper .owl-item:nth-child(-n+4) {
  display: block !important;
}

.owl-controls {
  display: none !important;
}


//cart page
.cart-voucher,
.cart-actions--print {
  width: 100% !important;
}


//product detail page
.image-gallery__image,
.image-gallery__image .owl-wrapper,
.image-gallery__image .owl-wrapper .owl-item {
  width: 190% !important;
}

.tabs .tabhead + .tabbody {
  display: block !important;
}

.back-link .label {
  border: 0;
}

.carousel-component {
  overflow: hidden;
  height: 320px;
}

// Compare Products
.meta-nav,
.header__right,
.header__navigation,
.header__mobile,
.btn,
.productcompare-remove-button,
.productcompare-component .productcompare-element-table-value-name-cell:empty,
.breadcrumb-section,
button,
footer {
  display: none !important;
}

header.header__container,
header.header__container.sticky {
  display: none;
}

.main-content{
  padding: 0 !important;
}

.campaign__text {
  background-color: @product-campaign-bg !important;
  color: @product-campaign-color !important;
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

.productcompare-component {
  margin: 0;

  .productcompare-element {
    display: grid;
    grid-template-areas: "image" "info" "prices";

    form {
      display: none;
    }

    .productcompare-element-image {
      grid-area: image;
    }

    .product__info {
      grid-area: info;
    }

    .product__prices {
      grid-area: prices;
    }

  }

  .productcompare-table-wrapper {
    max-width: 100%;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;

    .simple-banner-printer {
      margin-top: 0px;
      width: 330px;
      margin-bottom: 20px;
      display: block;
    }
  }

  .productcompare-table th,
  .productcompare-element-class-cell {
    background-color: @GRAY-LIGHT !important;
  }
}

tr {
  break-after: region;
}

.productcompare-element-name-cell,
.productcompare-component .productcompare-element-name-cell,
.productcompare-component .productcompare-element-class-cell,
.productcompare-component .productcompare-element-feature-value-cell {
  display: table-cell !important;
  font-size: 12px;
  padding-left: 10px;
  orphans: 3;
}

.productcompare-element-feature-value-cell.first {
  hyphens: auto;
}


table.temporary-printout-table-for-compared-products {
  display: table !important;
}

.printer-page() {
  page-break-after: always;
  .productcompare-table {
    display: table;
  }
  .productcompare-element-name-cell,
  .productcompare-element-class-cell,
  .productcompare-element-feature-value-cell {
    display: none !important;
  }
}

.printer-page-1-1,
.printer-page-2-1,
.printer-page-3-1 {
  .printer-page();

  .productcompare-element-name-cell,
  .productcompare-element-feature-value-cell,
  .productcompare-element-class-cell {
    display: table-cell !important;
  }
}


.printer-page-4-1 {
  .printer-page();

  .productcompare-element-name-cell,
  .productcompare-element-feature-value-cell,
  .productcompare-element-class-cell {
    &:nth-child(1),
    &:nth-child(2) {
      display: table-cell !important;
    }
  }
}

.printer-page-4-2 {
  .printer-page();

  .productcompare-element-name-cell,
  .productcompare-element-feature-value-cell,
  .productcompare-element-class-cell {
    &:nth-child(3),
    &:nth-child(4) {
      display: table-cell !important;
    }
  }
}

.printer-page-5-1 {
  .printer-page();

  .productcompare-element-name-cell,
  .productcompare-element-feature-value-cell,
  .productcompare-element-class-cell {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      display: table-cell !important;
    }
  }

}

.printer-page-5-2 {
  .printer-page();

  .productcompare-element-name-cell,
  .productcompare-element-feature-value-cell,
  .productcompare-element-class-cell {
    &:nth-child(4),
    &:nth-child(5) {
      display: table-cell !important;
    }
  }
}